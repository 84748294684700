import axios from 'axios'

const showStatus = (status: number) => {
  // TODO: Toast
  let message = ''
  switch (status) {
    case 401:
      message = '401'
      break
    case 403:
      message = '403'
      break
    case 404:
      message = '404'
      break
    default:
      message = 'error'
  }
  return message
}

const service = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? `http://47.110.126.186:3010`
      : 'http://localhost:5000',
  headers: {
    // TODO: Authorization
  },
  timeout: 10000,
  validateStatus() {
    return true
  },
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.resolve(error)
  },
)

service.interceptors.response.use(
  response => {
    const status = response.status
    if (status < 200 || status >= 300) {
      showStatus(status)
    }
    return response
  },
  error => {
    return Promise.resolve(error)
  },
)

export default service
